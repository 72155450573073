@font-face {
    font-family: 'layerswitcher-icons';
    src: url('../fonts/layerswitcher-icons.eot?35225629');
    src: url('../fonts/layerswitcher-icons.eot?35225629#iefix') format('embedded-opentype'),
        url('../fonts/layerswitcher-icons.woff2?35225629') format('woff2'),
        url('../fonts/layerswitcher-icons.woff?35225629') format('woff'),
        url('../fonts/layerswitcher-icons.ttf?35225629') format('truetype'),
        url('../fonts/layerswitcher-icons.svg?35225629#layerswitcher-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="m-layerswitcher-icons-"], [class*=" m-layerswitcher-icons-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'layerswitcher-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.m-layerswitcher-icons-check:before {
  content: "\e909";
}

.m-layerswitcher-icons-codsi:before {
  content: "\e94f";
}

.m-layerswitcher-icons-layer-group:before { 
  content: '\f114'; 
  font-weight: bold;
}

.m-layerswitcher-icons-spinner:before {
  content: "\e953";
}

.m-layerswitcher-icons-check-seleccionado:before {
  content: "\e90a";
}

.m-layerswitcher-icons-desplegar:before {
  content: "\e916";
}

.m-layerswitcher-icons-colapsar:before {
  content: "\e918";
}

.m-layerswitcher-icons-lista:before {
  content: "\e92c";
}

.m-layerswitcher-icons-lista:before {
  content: "\e92c";
}

.m-layerswitcher-icons-zoom:before {
  content: "\e964";
}

.m-layerswitcher-icons-ver:before {
  content: "\e801";
}

.m-layerswitcher-icons-ocultar:before {
  content: "\e802";
}

.m-layerswitcher-icons-layers:before {
  font-family: 'layerswitcher-icons' !important;
  content: "\e907";
}

.m-layerswitcher-icons-image:before {
  content: "\e90d";
}

.m-layerswitcher-icons-target:before {
  content: "\e9b3";
}

.m-layerswitcher-icons-info:before {
  content: "\ea0c";
}

.m-layerswitcher-icons-delete:before {
  content: "\e9ac";
}

.m-layerswitcher-icons-style:before {
  content: "\e90c";
}

.m-filter-btn:before {
  content: "\e964";
}

.m-clean-btn:before {
  content: "\e9ac";
}

.m-layerswitcher-icons-cancel:before { 
  content: '\e800'; 
}

.m-layerswitcher-icons-upload:before {
  content: '\e95e';
}

.m-layerswitcher-icons-doc-text:before {
  content: '\f0f6';
}
