/* PANEL */

.m-panel.m-plugin-layerswitcher {
    box-shadow: unset !important;
    background-color: transparent !important;
}

.m-panel.m-plugin-layerswitcher .m-panel-controls {
    background-color: #f9f9f9;  
}


.m-panel.m-plugin-layerswitcher .m-panel-controls .m-control.m-layerswitcher-container>div>ul {
    max-height: 85vh;
    overflow-y: auto;
}

div.m-areas div.m-area .m-panel.m-plugin-layerswitcher.opened {
    background-color: transparent !important;
}

.m-panel.m-plugin-layerswitcher.opened button.m-panel-btn {
    background-color: #71a7d3 !important;
    color: white !important;
}

.m-panel.m-plugin-layerswitcher.collapsed button.m-panel-btn {
    color: white !important;
}

.m-layerswitcher-container {
    position: inherit;
    text-align: left;
    overflow-y: auto !important;
    width: 100%;

    max-height: 85vh;
    min-width: 150px;

    overflow: hidden;
    background-color: transparent;
    border-radius: 0;

    box-shadow: none;
    display: table;

    margin: 0;
    padding: 0px;

    z-index: 990;
}

.m-layerswitcher-container #m-layerswitcher-panel {
    margin: 0;
    top: 0px;
    right: 0px;
    max-height: 100%;
    text-align: left;
    background-color: #f4f4f4;
}

.m-layerswitcher-title-box {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    max-width: 250px;
}

.m-layerswitcher-title-box:hover {
    cursor: pointer;
    color: #71a7d3;
}

/* Título */
.m-layerswitcher-title {
    font-weight: bold !important;
    touch-action: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #f4f4f4;
    height: 40px;

    position: relative; /* Center h1*/
}

.m-layerswitcher-title h1 {
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    line-height: 40px;
    width: 100%;
}

/* Listado de capas */
.m-layerswitcher-container li div {
    display: flex;
    font-size: 0.8em;
    overflow: hidden;
    line-height: 1.4em;
    line-height: 40px;
    text-align: center;
    font-size: 15px;
}

.m-layerswitcher-radioGroup {
    margin: 10px;
} 

.m-layerswitcher-content {
    text-align: left;
}

.m-layerswitcher-content ul li {
  border: 1px solid rgba(138, 134, 134, 0.73);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.85);
}

#m-layerswitcher-panel>ul {
    margin: 0px;
    padding: 0px;
}

.m-layerswitcher-sectionPanel {
    background-color: white;
    margin-bottom: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
    overflow: hidden;
}

.m-layerswitcher-sectionPanel-header-text {
    margin: 4px;
}

.m-layerswitcher-sectionPanel-header-text:hover {
    cursor: pointer;
    color: #71a7d3;
}

.m-layerswitcher-sectionPanel-header-text span {
    margin-right: 2px;
}

.m-layerswitcher-ullayersGroup {
    padding: 5px;
}

ul.m-layerswitcher-ullayers {
    background-color: #f9f9f9;
    list-style: none;
    padding: 0px 10px 0px 10px;
    max-height: 50vh !important;
    overflow-y: auto;
    min-width: 440px;
    max-width: 50vw;
}

ul.m-layerswitcher-ullayers .m-layerswitcher-sectionPanel-colorPanel button,
ul.m-layerswitcher-ullayers .m-layerswitcher-sectionPanel-colorPanel li button {
    background-color: #f4f4f400
}

ul.m-layerswitcher-ullayers .m-layerswitcher-sectionPanel-colorPanel input,
ul.m-layerswitcher-ullayers .m-layerswitcher-sectionPanel-colorPanel li input {
    background-color: #f4f4f400
}

ul.m-layerswitcher-ullayers .m-layerswitcher-sectionPanel-colorPanel {
    background-color: #f4f4f4;
}

ul.m-layerswitcher-ullayers .m-layerswitcher-sectionPanel-colorPanel li {
    background-color: #ffffff;
}

ul.m-layerswitcher-ullayers .m-layerswitcher-sectionPanel-colorPanel .m-layerswitcher-sectionPanel-colorPanel{
    background-color: #e4f4ff;
}

ul.m-layerswitcher-ullayers .m-layerswitcher-sectionPanel-colorPanel .m-layerswitcher-sectionPanel-colorPanel li {
    background-color: #f0f9ff;
}

ul.m-layerswitcher-ullayers .m-layerswitcher-sectionPanel-colorPanel .m-layerswitcher-sectionPanel-colorPanel .m-layerswitcher-sectionPanel-colorPanel{
    background-color: #e2e2e291;
}

ul.m-layerswitcher-ullayers .m-layerswitcher-sectionPanel-colorPanel .m-layerswitcher-sectionPanel-colorPanel .m-layerswitcher-sectionPanel-colorPanel li {
    background-color: #ffffff91;
}



/* Etiqueta tipo */
.m-layerswitcher-container .m-layerswitcher-type {
    color: white;
    background-color: #9a9a9a;
    padding: 3px 7px;
    border-radius: 5px;
    font-weight: bold;
}

.m-layerswitcher-groupDisplay {
    font-size: 1.1rem;
    margin-top: 4px;
}

.m-layerswitcher-sectionPanel-header-tools {
    height: 40px;
    gap: 0px !important;
}

/* Icono ver */
.m-layerswitcher-title-layer {
    display: flex;
    width: 100%;
    align-items: center;
}

.m-layerswitcher-visible-control {
    width: 10%;
}

.m-layerswitcher-sectionPanel-header-bt {
    justify-content: space-around;
}

.m-layerswitcher-sectionPanel-header-bt-desplegar {
    font-weight: bold;
}


.m-layerswitcher-sectionPanel-header div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.m-layerswitcher-sectionPanel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0 0 5px;
}

.m-layerswitcher-visible-control-button {
    border: 1px solid rgba(0,0,0,.1) !important;
    box-shadow: 0 1px 2px rgba(0,0,0,.3);
    cursor: pointer;
    background-color: transparent;
}

.m-layerswitcher-visible-control > button {
    border: 1px solid rgba(0,0,0,.1) !important;
    box-shadow: 0 1px 2px rgba(0,0,0,.3);
    cursor: pointer;
 }

.m-layerswitcher-visible-typeContent {
    width: 83%;
}

.m-layerswitcher-title-layer span {
    width: 100%;
}

.m-layerswitcher-title-layer button {
    border: none;
    background-color: transparent;
}

.m-layerswithcer-ogc-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}


.m-layerswitcher-icons-ver,
.m-layerswitcher-icons-ocultar {
    margin: 0 5px;
}

.m-layerswitcher-allstatus {
    border: none;
    background-color: #f9f9f9;
    margin: 0 16px;
    color: orange !important;
    margin: 10px 0px 0px 16px;
    display: block;
}

#m-layerswitcher-hsalllayers {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
    cursor: pointer;
    position: absolute;
    top: 4px;
}

.m-layerswitcher-icons-ver {
    color: #6abe47;
}

.m-layerswitcher-icons-ocultar {
    color: red;
}

.m-layerswitcher-outOfRange {
    color: #9a9a9a;
}

.m-layerswitcher-radio {
    justify-content: center;
}

/* Estilo personalizado para los inputs de tipo radio */
.m-layerswitcher-container input[type="radio"] {
    border-color: #6abe47;
    appearance: none;
    -webkit-appearance: none;
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
    vertical-align: middle;
    margin: 0px;
    border: 2px solid #6abe47;
}

.m-layerswitcher-change-name {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.m-layerswitcher-change-name p {
    text-align: center;
    width: 100%;
}

.m-layerswitcher-change-name p input {
    width: 70%;
}

.m-layerswitcher-change-name button {
    width: 90px;
    background-color: rgb(113, 167, 211);
    color: #fff;
    border: none;
    box-shadow: none;
    cursor: pointer;
    font-weight: 700;
    margin-top: 10px;
    padding: 5px;
}


.m-layerswitcher-sectionPanel-header div input[type="radio"] {
    margin-right: 5px;
    margin-left: 10px;
}

.m-layerswitcher-container input[type="radio"]:checked {
    background-color: #6abe47;
    border-color: #6abe47;
}

.m-layerswitcher-outOfRange-input {
    border-color: #9a9a9a !important;
}

.m-layerswitcher-outOfRange-input:checked {
    background-color: #6abe47;
}

/* Input range */
.m-layerswitcher-tools .m-layerswitcher-opacity {
    margin-left: 10px;
    min-width: 50%;
    max-width: 50%;
}

.m-layerswitcher-tools {
    margin-top: -0.5rem;
    padding: 5px 0 10px 0;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #f1ecec;
}


input[type=range].m-layerswitcher-transparency {
    -webkit-appearance: none;
    width: 98%;
    height: 8px;
}


input[type=range].m-layerswitcher-transparency:focus {
    outline: none;
}

.m-layerswitcher-container input[type=range] {
    padding: 15px 0px 4px 0px;
}

.m-layerswitcher-container input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.31);
    border-radius: 12px;
}

.m-layerswitcher-container input[type=range]::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.31);
    border-radius: 12px;
}


.m-layerswitcher-container input[type=range]::-webkit-slider-thumb {
    height: 10px;
    width: 10px;
    cursor: pointer;
    border-radius: 15px;
    background: #FFF;
    -webkit-appearance: none;
    margin-top: -4px;
    background-color: #fda823;
}

.m-layerswitcher-container input[type=range]::-moz-range-thumb {
    height: 10px;
    width: 10px;
    cursor: pointer;
    border-radius: 15px;
    background: #FFF;
    -webkit-appearance: none;
    margin-top: -4px;
    background-color: #fda823;
}

/* legend */
.m-layerswitcher-action-tools {
    display: flex;
    text-align: left !important;
    padding: 5px 10px 5px 10px;
}

.m-layerswitcher-action-tools span {
    cursor: pointer;
}

.m-layerswitcher-icons-image,
.m-layerswitcher-icons-delete,
.m-layerswitcher-icons-target,
.m-layerswitcher-icons-info,
.m-layerswitcher-icons-style
{
    font-size: 18px;
    position: relative;
    top: 3px;
}

.m-layerswitcher-icons-image,
.m-layerswitcher-icons-delete,
.m-layerswitcher-icons-target,
.m-layerswitcher-icons-info,
.m-layerswitcher-icons-style,
.m-layerswitcher-icons-zoom,
.m-layerswitcher-icons-codsi,
.m-layerswitcher-icons-lista,
.m-layerswitcher-suggestion,
.m-layerswitcher-icons-doc-text,
.m-layerswitcher-icons-upload,
.m-layerswitcher-icons-layer-group
{
    background-color: #ffffff;
    border: none;
    color: #6c6c6c;
}

.m-layerswitcher-icons-upload {
    cursor: pointer;
}

.m-layerswitcher-suggestion {
    font-size: 15px;
}

.m-layerswitcher-icons-image:hover,
.m-layerswitcher-icons-delete:hover,
.m-layerswitcher-icons-target:hover,
.m-layerswitcher-icons-info:hover,
.m-layerswitcher-icons-style:hover {
    cursor: pointer;
    color: black;
}

.m-layerswitcher-icons-delete {
    width: 10%;
}

div.m-layerswitcher-legend {
    max-width: 420px;
    display: none;
    overflow-x: auto;
}

div.m-layerswitcher-legend>img {
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
}

/* information */
.m-layerswitcher-btn-container {
    margin: 0 auto;
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
}

.m-layerswitcher-info-cap {
    max-width: 100%;
    max-height: 230px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #cccccc;
}

.m-layerswitcher-info-cap>p>span, .m-layerswitcher-info-cap > div >p>span  {
    font-weight: bold;
}

.m-layerswitcher-otherTitles {
    margin-bottom: 0.2rem;
}

.m-layerswitcher-provider-link {
    font-weight: bold;
    padding-top: 7px;
    text-decoration: none;
}

.m-layerswitcher-provider-link:hover {
    text-decoration: underline;
}

.m-layerswitcher-common-btn {
    padding: 8px 7px;
    background-color: #71a7d3;
    font-family: Muli, "sans-serif" !important;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #71a7d3;
    color: white;
    font-weight: bold;
    margin-right: 10px;
    outline: none;
    text-decoration: none;
}

/* style */
.m-layerswitcher-style-container {
    text-align: center;
    padding: 10px;
}

.m-layerswitcher-style-btn-container {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
}

#m-layerswitcher-ogc-vectors-select,
#m-layerswitcher-style-select {
    background-color: white;
    padding: 5px;
    width: 100%;
    border: solid 1px #222;
    -webkit-appearance: none;
    color: #222;
}

#m-layerswitcher-ogcCContainer>div:after,
.m-layerswitcher-style-container:after {
    content: '\0025BC';
    font: normal normal normal 12px/1 FontAwesome;
    color: black;
    position: absolute;
    pointer-events: none;
    background-color: white;
    padding: 7px 6px 6px 7px;
}

#m-layerswitcher-ogcCContainer>div:after {
    right: 34px;
    top: 114px;
    height: 16px;
}

.m-layerswitcher-style-container:after {
    right: 27px;
    top: 72px;
    height: 10px;
}

.m-layerswitcher-style-common-btn {
    padding: 8px 7px;
    background-color: #71a7d3;
    font-family: Muli, "sans-serif" !important;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #71a7d3;
    color: white;
    font-weight: bold;
}

.m-layerswitcher-gray-shadow {
    opacity: 0.3;
    background-color: #D1E6F8;

}

/* leyenda */
.m-layerswitcher-legend {
    max-width: 420px;
    overflow-x: auto !important;
}

.m-layerswitcher-legend-error {
    margin-top: 0;
    margin-bottom: 0;
}

.m-layerswitcher-icons-cancel{
    color: red;
    font-size: 15px;
    margin-right: 4px;
}

/* anadir capas */
.m-layerswitcher-addservice {
    cursor: pointer;
    background-color: #71a7d3;
    color: #fff;
    line-height: 25px;
    padding: 0 7px;
    margin-right: 9px;
    border: 1px solid rgba(64, 64, 64, .19215686274509805);
    font-size: 13px;
    font-weight: 700 !important;
    float: right;
}

.m-layerswitcher-addservices .m-layerswitcher-search-panel .input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.m-layerswitcher-addservices .m-layerswitcher-search-panel .input-container input {
    width: 70%;
}

.m-layerswitcher-addservices .m-layerswitcher-search-panel .input-container span button {
    padding: 0px;
    cursor: pointer;
    color: #6c6c6c !important;
    opacity: 1 !important;
    font-size: 16px !important;
    width: 23px;
}

.m-layerswitcher-addservices-suggestions {
    margin: 1rem 0.5rem;
    display: block;
    overflow-y: auto;
    max-height: 50vh;
    overflow-x: auto;
}

.m-layerswitcher-addservices-suggestions>table {
    width: 100%;
}

.m-layerswitcher-addservices-suggestions table thead tr th {
    padding-bottom: 0.5rem;
}

.m-layerswitcher-addservices-suggestions table tbody tr td button {
    cursor: pointer;
}

.m-layerswitcher-suggestion-caret {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 700;

    background-color: #ffffff;
    border: none;
    color: #6c6c6c;
    font-size: 15px;
}

.m-layerswitcher-suggestion-group.active {
    display: block;
}

.m-layerswitcher-suggestion-group.active>li {
    color: #71a7d3;
}

.m-layerswitcher-suggestion-group {
    display: none;
    margin: 0;
}

#m-layerswitcher-addservices-results {
    min-width: 400px;
    max-width: 500px;
    margin: 0 auto;
    overflow: auto;
    max-height: 50vh;
}

.m-layerswitcher-suggestion-caret {
    cursor: pointer;
    -webkit-user-select: none;
    /* Safari 3.1+ */
    -moz-user-select: none;
    /* Firefox 2+ */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    font-weight: bold;
}

#m-layerswitcher-tfoot {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* Firefox fix */
@-moz-document url-prefix() {
    .m-bottom.m-right .m-plugin-layerswitcher.opened {
        display: flex;
    }

    div.m-panel-controls>div.m-control.m-container.m-layerswitcher {
        padding-right: unset;
    }

    div.m-panel-controls>div.m-control.m-container.m-layerswitcher {
        padding-right: 0px;
    }
}

/* Mobile fix */

@media only screen and (max-width: 768px) {

    .sortable-chosen .m-layerswitcher-type {
        color: white;
        background-color: #9a9a9a;
        padding: 3px 7px;
        border-radius: 5px;
        font-weight: bold;
    }

    /* Ocultar cuando se mueve drag and drop*/
    .sortable-chosen ul li, .sortable-chosen .m-layerswitcher-tools {
        display: none;
    }

    ul.m-layerswitcher-ullayersGroup li {
        list-style: none !important;
    }

    .sortable-chosen {
        color: #6c6c6c;
        font-family: Muli, "sans-serif" !important;
        font-size: 13px;
        height: 100%;
    
        list-style: none;
    
        display: flex;
        justify-content: center;
        align-items: center;
    
        gap: 20px;
    
        line-height: 40px;
        text-align: center;
        font-size: 15px;
    }
    
    .m-layerswitcher-sectionPanel-header-text {
        white-space: nowrap; 
        overflow: hidden;            
        text-overflow: ellipsis;   
        width: 200px; 
    }

    .m-plugin-layerswitcher.opened {
        width: 100vw;
        height: 100vh;
        position: fixed !important;
        top: 0;
        left: 0;
        margin: 0 !important;
        z-index: 999999 !important;
    }

    .m-plugin-layerswitcher.opened .m-layerswitcher-container {
        top: 0;
        position: absolute;
    }

    .m-areas>div.m-area.m-left>div.m-plugin-layerswitcher.opened>button.m-panel-btn {
        right: 0;
    }

    .m-plugin-layerswitcher.opened button.m-panel-btn::before {
        content: "\e907" !important;
    }

    .m-plugin-layerswitcher.opened .m-panel-btn.g-cartografia-flecha-derecha,
    .m-plugin-layerswitcher.opened .m-panel-btn.g-cartografia-flecha-izquierda {
        position: absolute;
        left: auto !important;
        right: 0;
        z-index: 999999;
        box-shadow: none;
    } 

    div.m-area.m-top.m-right > div.m-panel.m-plugin-layerswitcher.opened > div, .m-control.m-container.m-layerswitcher,
    div.m-area.m-top.m-left > div.m-panel.m-plugin-layerswitcher.opened > div, .m-control.m-container.m-layerswitcher,
    div.m-area.m-buttom.m-right > div.m-panel.m-plugin-layerswitcher.opened > div, .m-control.m-container.m-layerswitcher,
    div.m-area.m-buttom.m-left > div.m-panel.m-plugin-layerswitcher.opened > div, .m-control.m-container.m-layerswitcher  {
        max-width: 100vw !important;
        max-height: 100vh !important;
        width: 100% !important;
    }

    header.m-layerswitcher-title {
        font-size: 13px;
        padding-left: 0;
        padding-right: 0;
        margin-right: 40px;
    }
    
    div.m-panel.m-plugin-layerswitcher.opened>.m-panel-controls {
        height: 100%;
        width: 100%;
    }

    ul.m-layerswitcher-ullayers {
        max-height: 86vh !important;
        max-width: 100vw;
        min-width: 50px;
        overflow: scroll;
    }
        
    .m-panel.m-plugin-layerswitcher .m-panel-controls .m-control.m-layerswitcher-container>div>ul {
        max-height: 100%;
        overflow-y: auto;
    }

    #m-layerswitcher-addservices-results {
        min-width: 50px;
    }

    #m-layerswitcher-tfoot {
        flex-wrap: wrap;
    }

    /* Firefox fix */

    @-moz-document url-prefix() {
        .m-bottom.m-left>.m-panel.m-plugin-layerswitcher.opened>.m-panel-controls {
            display: flow-root;
        }

        .m-bottom.m-left>.m-panel.m-plugin-layerswitcher.opened>.m-panel-controls {
            display: flex;
        }

        .m-bottom.m-left>.m-panel.m-plugin-layerswitcher.opened>button {
            left: 0px;
        }

        .m-areas>div.m-area>div.m-panel>div.m-panel-controls {
            display: flex;
        }
    }

    /* Edge fix */

    @supports (-ms-ime-align:auto) {

        .m-areas>div.m-area>div.m-panel>div.m-panel-controls {
            padding-left: 0px;
        }

        .m-bottom.m-left>.m-plugin-layerswitcher.opened>.m-panel-btn {
            position: static;
        }
    }
}

/* Edge fix */
@supports (-ms-ime-align:auto) {
    .m-areas>div.m-area>div.m-panel>div.m-panel-controls {
        padding-left: 40px;
    }

    .m-bottom.m-left>.m-plugin-layerswitcher.opened>.m-panel-btn {
        right: 0px;
    }
}

div#m-layerswitcher-layerContainer>p {
    text-align: center;
}

button.m-layerswitcher-suggestion-caret,
button.m-layerswitcher-suggestion {
    text-align: start;
}

.g-cartografia-flecha-derecha.m-layerswitcher-hidden,
.g-cartografia-flecha-izquierda.m-layerswitcher-hidden {
    display: none;
} 

.m-layerswitcher-fixedwidthbutton {
    width: 16px;
    text-align: center;
}

div.m-layerswitcher-pagecontrolscontainer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

#m-layerswitcher-next,
#m-layerswitcher-previous {
    cursor: pointer;
}

table#m-layerswitcher-table{
    margin-left: auto;
    margin-right: auto;
}

table#m-layerswitcher-table.m-layerswitcher-tablepagination thead tr {
    background-color: rgb(113, 167, 211);;
    color: white;
    font-size: large;
    padding: 5px 5px 5px 5px;
}

table#m-layerswitcher-table.m-layerswitcher-tablepagination tbody tr {
    border-bottom: 1px solid #dddddd;
}

table#m-layerswitcher-table.m-layerswitcher-tablepagination tbody tr td,
table#m-layerswitcher-table.m-layerswitcher-tablepagination thead tr td {
    padding: 5px;
}

table#m-layerswitcher-table.m-layerswitcher-tablepagination tbody tr:nth-of-type(even) {
    background-color: rgb(113 167 211 / 24%);
}

table#m-layerswitcher-table.m-layerswitcher-tablepagination tbody tr:hover {
    background-color: rgb(113 167 211 / 50%);
}

.m-layerswitcher-suggestion-caret::before {
    font-family: "layerswitcher-icons" !important;
    content: '\e916';
    display: inline-block;
    margin-right: 6px;
}

.m-layerswitcher-suggestion-caret-close::before {
    font-family: "layerswitcher-icons" !important;
    content: '\e918';
}

/* show services */
#m-layerswitcher-addservices-results>div {
    margin: 1rem 0.5rem 0;
}

#m-layerswitcher-addservices-results .m-layerswitcher-service-capabilities>p {
    text-align: center;
    width: 100%;
    cursor: pointer;
}

#m-layerswitcher-addservices-results .m-layerswitcher-capabilities-container {
    border: 1px solid #ccc;
    padding: 10px;
    display: none;
}

#m-layerswitcher-addservices-results .m-layerswitcher-capabilities-container p {
    font-weight: 700;
    margin-bottom: 0.2rem;
}

#m-layerswitcher-addservices-results .m-layerswitcher-capabilities-container div {
    word-break: break-word;
}

#m-layerswitcher-addservices-results .m-layerswitcher-capabilities-container-wfs {
    border: 1px solid #ccc;
    padding: 10px;
    display: none;
}

#m-layerswitcher-addservices-results .m-layerswitcher-capabilities-container-wfs p {
    font-weight: 700;
    margin-bottom: 0.2rem;
}

#m-layerswitcher-addservices-results .m-layerswitcher-capabilities-container-wfs div {
    word-break: break-word;
}

#m-layerswitcher-addservices-results>div {
    margin: 1rem 0.5rem 0;
}

.m-layerswitcher-table-results .m-layerswitcher-table-container {
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
    width: calc(100% - 20px) !important;
}

.m-layerswitcher-table-results .m-layerswitcher-table-container>table>tbody>tr>td:first-child {
    text-align: center;
}


.m-layerswitcher-table-results .m-layerswitcher-table-container table thead tr th:last-child {
    text-align: inherit;
}

#m-layerswitcher-addservices-results .button ,
#m-layerswitcher-layerContainer>div.button {
    margin-top: 1rem;
    text-align: center;
}

.m-layerswitcher-common-btn {
    padding: 8px 7px;
    background-color: #71a7d3;
    font-family: Muli, "sans-serif" !important;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #71a7d3;
    color: #fff;
    font-weight: 700;
    margin-right: 10px;
    outline: none;
    text-decoration: none;
}

#m-layerswitcher-addservices-selectall,
#m-layerswitcher-addservices-selectall-wfs {
    font-size: 18px !important;
    font-weight: bold;

    background-color: #ffffff;
    border: none;
    color: #6c6c6c;
}

.m-layerswitcher-icons-desplegar, .m-layerswitcher-icons-colapsar{
    background-color: #ffffff;
    border: none;
    color: #6c6c6c;
}

/* ogcmodal */
#m-layerswitcher-ogc-vectors-select {
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 0.5rem;
    border-radius: 10px;
    background: #fff;
    width: 100%;
    border-radius: 0;
}

.m-layerswitcher-ogc-fieldset-customquery {
    border: 1.5px solid #d3d3d3;
    padding-top: 15px;
    padding-bottom: 15px;
}

.m-layerswitcher-ogc-input-customquery {
    width: 15px;
    float: left;
    margin: 0 !important;
    width: auto !important;
}

.m-layerswitcher-ogc-label-customquery {
    float: left;
}

.m-layerswitcher-ogc-filter-custom-query {
    border: 1.5px solid #d3d3d3;
    padding-top: 15px;
    padding-bottom: 15px;
}

#m-layerswitcher-ogc-form-input>input {
    margin-top: 10px;
    width: 150px;
    float: left;
}

#m-layerswitcher-ogc-form-input>label {
    margin-top: 15px;
    width: 180px;
    text-align: right;
    float: left;
}

#m-layerswitcher-ogc-form-input>label.m-layerswitcher-ogc-search-form-id {
    width: fit-content;
}

#m-layerswitcher-ogc-limit-items {
    text-align: left;
    width: 2.5rem;
    margin-right: 1px;
}

#m-layerswitcher-ogc-other-filters {
    text-align: left;
}


#m-layerswitcher-ogc-search-bbox {
    width: auto !important;
}

/* mas filtros */
.m-layerswitcher-ogc-warning-msg-custom-query {
    max-width: 500px;
    margin: auto;
    text-align: justify;
    padding: 5px;
}

.m-layerswitcher-ogc-warning-msg {
    background-color: #fff3cd;
}

.m-layerswitcher-ogc-form-input {
    display: flex;
    align-items: center;
    justify-content: center;
}

.m-layerswitcher-ogc-custom-query-form-label {
    width: 180px;
    clear: left;
    text-align: right;
    padding-right: 10px;
    float: left;
    display: flex;
    align-items: center;
}

#m-layerswitcher-ogc-form-input>input {
    margin-top: 10px;
    width: 150px;
    float: left;
}

.search-form-date,
.search-form-number {
    margin: 5px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #757474;
    color: #6c6c6c;
    font-family: Muli, "sans-serif" !important;
    font-size: 13px;
    outline: none;
    width: 134px;
}

@-moz-document url-prefix(){
    .search-form-date,
    .search-form-number {
        width: 161px;
    }
}

.m-layerswitcher-ogc-more-width {
    width: 245px;
}

.m-layerswitcher-ogc-less-width {
    width: 100px;
}

/* GeoJSON modal */
#m-layerswitcher-geojson-container div {
    display: flex;
    align-items: center;
    justify-content: center;
}

#m-layerswitcher-geojson-container p {
    margin-bottom: 8px;
}

#m-layerswitcher-layer-name,
#m-layerswitcher-layer-legend,
#m-layerswitcher-layer-matrixset {
    width: 40%;
}

.m-layerswitcher-layer-label {
    width: 75px;
    clear: left;
    text-align: right;
    padding-right: 10px;
    float: left;
    display: flex;
    align-items: center;
}

.m-layerswitcher-layer-fields {
    display: flex;
    align-items: center;
    justify-content: center;
}

#m-layerswitcher-layer-button {
    margin-top: 18px;
    margin-bottom: 10px;
}

/* check others */
#m-layerswitcher-layerContainer .m-layerswitcher-table-layer-name {
    text-align: left !important;
}

#m-layerswitcher-layerContainer #m-layerswitcher-addservices-results {
    margin-bottom: 15px;
}

/* loading */
#m-layerswitcher-loading {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
}

#m-layerswitcher-loading span {
    font-size: 2rem;
    animation: mloading 1s linear infinite;
    -webkit-animation: mloading 1s linear infinite;
    -moz-animation: mloading 1s linear infinite;
}

/* codsi */


#m-layerswitcher-addservices-codsi {
    max-width: 520px;
    margin: 0 auto;
}

#m-layerswitcher-addservices-codsi>p {
    text-align: center;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

#m-layerswitcher-addservices-codsi-pagination {
    text-align: center;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}

#m-layerswitcher-addservices-codsi-pagination button.m-layerswitcher-addservices-pagination-btn {
    padding: 3px 7px;
    background-color: #71a7d3;
    font-family: Muli, "sans-serif" !important;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #71a7d3;
    color: white;
    font-weight: bold;
    margin-right: 5px;
    margin-bottom: 5px;
    outline: none;
    text-decoration: none;
}

#m-layerswitcher-addservices-codsi-pagination button.m-layerswitcher-addservices-pagination-btn:disabled {
    color: #767676;
    background-color: #eeeeee;
    border-color: #eeeeee;
}

#m-layerswitcher-addservices-codsi-results table tbody tr td span {
    cursor: pointer;
}

#m-layerswitcher-addservices-codsi-results table tbody tr td span:hover {
    text-decoration: underline;
}


.m-layerswitcher-addservices-codsi-search-panel {
    text-align: center;
    margin-bottom: 6px;
}

.m-layerswitcher-addservices-codsi-search-panel input {
    width: 160px;
}

.m-layerswitcher-addservices-codsi-search-panel span {
    margin-left: 3px;
    cursor: pointer;
    color: #6c6c6c !important;
    opacity: 1 !important;
    font-size: 14px !important;
}

div.codsi-no-results {
    text-align: center;
    margin-top: 1rem;
}

#m-layerswitcher-addservices-codsi-results {
    font-size: 12px;
}

#m-layerswitcher-addservices-codsi-results > tr > td > span {
    cursor: pointer;
}

span.m-layerswitcher-codsi-result::before {
    content: '\2022';
    margin-right: 10px;
    color: #71a7d3;
}

/* MODAL */
div.m-api-idee-container div.m-dialog div.m-title {
    background-color: #71a7d3;
}

div.m-dialog.info div.m-button > button {
    width: 75px;
    background-color: #71a7d3;
}

.m-dialog.error div.m-button>button {
    background-color: #71a7d3 !important;
}

button.g-cartografia-flecha-izquierda + div .m-layerswitcher-ullayersGroup {
    padding-left: 10px;
}

button.g-cartografia-flecha-derecha + div .m-layerswitcher-ullayersGroup {
    padding-right: 10px;
}

.m-layerswitcher-groups-fields-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.m-layerswitcher-groups-fields-container > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.m-layerswitcher-groups-fields {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #464646;
    cursor: pointer;
    font-family: Muli, sans-serif;
    font-size: 13px;
    padding: 5px;
    margin-top: 5px;
    width: 60%;
}

li.m-layerswitcher-sectionPanel[data-layer-type="LayerGroup"]:has(> section div button.m-layerswitcher-icons-ocultar) > ul > li button.m-layerswitcher-icons-ver {
    color: #9a9a9a !important;
}

li[data-layer-type="LayerGroup"]:not(:has(> section div input[type="radio"]:checked)) > ul > li input[type="radio"]:checked{
    background-color: #9a9a9a !important;
    border-color: #9a9a9a !important;
}

